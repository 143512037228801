<template>
  <div class="commercial-list">
    <section class="table-search">
      <div class="search-L">
        <el-input
          v-model="tableParams.keyWord"
          placeholder="查询商户名称"
          @input="kwChange"
          class="mr20"
        />
        <el-select
          v-model="tableParams.merchantStatus"
          placeholder="请选择状态"
          @change="reload"
          clearable
        >
          <el-option
            v-for="item in ObjToOpt(merchantStatusObj)"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="search-R">
        <el-button type="primary" @click="onAdd()">新增商户</el-button>
      </div>
    </section>
    <!-- 表格 -->
    <el-table
      :data="tableData"
      border
      stripe
      :header-cell-style="{
        background: '#FAFAFA',
        color: '#000000',
      }"
    >
      <el-table-column label="商户名称" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.merchantName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="商户法人" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.legalPerson || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="商户超管" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.adminUserName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="超管手机" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.telPhone || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="商户地域" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.province }}</span>
          <span>{{ scope.row.city }}</span>
          <span>{{ scope.row.district }}</span>
        </template>
      </el-table-column>
      <el-table-column label="店铺数量" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.shopNum }}</span>
        </template>
      </el-table-column>
      <el-table-column label="营业执照" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-image
            style="width: 48px; height: 48px"
            :src="scope.row.licenseUrl"
            :preview-src-list="[scope.row.licenseUrl]"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column label="注册时间" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.createTime }}</span>
        </template>
      </el-table-column>
      <el-table-column label="商户状态" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span
            :style="{ color: merchantStatusColor[scope.row.merchantStatus] }"
            >{{ merchantStatusObj[scope.row.merchantStatus] }}</span
          >
        </template>
      </el-table-column>
      <el-table-column width="200" label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            v-if="!scope.row.merchantStatus"
            type="text"
            size="medium"
            @click.stop="onStart(scope.row)"
            v-has="'commercial_commercial_start'"
            >启用</el-button
          >
          <el-button
            v-if="scope.row.merchantStatus"
            type="text"
            size="medium"
            @click.stop="onStop(scope.row)"
            v-has="'commercial_commercial_stop'"
            >停用</el-button
          >
          <el-button
            type="text"
            size="medium"
            @click.stop="onEdit(scope.row)"
            v-has="'commercial_commercial_edit'"
            >编辑</el-button
          >
          <!-- <el-button type="text" size="medium" @click.stop="onDel(scope.row)" v-if="!scope.row.merchantStatus"
            v-has="'commercial_commercial_del'">删除</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <div class="table-footer">
      <el-pagination
        layout="total,sizes, prev, pager, next,jumper"
        background
        :total="total"
        :page-size="tableParams.size"
        @size-change="onSizeChange"
        :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage"
        @current-change="onCurrentPage"
      >
      </el-pagination>
    </div>
    <!-- 编辑弹框 -->
    <el-dialog
      :visible.sync="editShow"
      :title="editTitle"
      :close-on-click-modal="false"
      width="30%"
      custom-class="add-dialog"
    >
      <el-form :model="params" :rules="rules" ref="form-ref">
        <!-- 商户名称 -->
        <el-form-item label="商户名称" label-width="120px" prop="merchantName">
          <el-input
            v-model.trim="params.merchantName"
            autocomplete="off"
            maxlength="16"
            placeholder="请填写商户信息"
          ></el-input>
        </el-form-item>
        <!-- 商户法人 -->
        <el-form-item label="商户法人" label-width="120px" prop="legalPerson">
          <el-input
            v-model.trim="params.legalPerson"
            autocomplete="off"
            maxlength="16"
            placeholder="请填写商户法人"
          ></el-input>
        </el-form-item>
        <!-- 商户超管 -->
        <el-form-item label="商户超管" label-width="120px" prop="adminUserName">
          <el-input
            v-model.trim="params.adminUserName"
            autocomplete="off"
            maxlength="16"
            :disabled="editTitle == '编辑商户'"
            placeholder="请填写商户超管"
          ></el-input>
        </el-form-item>
        <!-- 超管手机 -->
        <el-form-item label="超管手机" label-width="120px" prop="telPhone">
          <el-input
            type="number"
            v-model="params.telPhone"
            autocomplete="off"
            :disabled="editTitle == '编辑商户'"
            placeholder="请填写超管手机"
          ></el-input>
        </el-form-item>
        <!-- 特约商户号 -->
        <el-form-item label="商户号" label-width="120px" prop="merchantNo">
          <el-input
            type="number"
            v-model="params.merchantNo"
            autocomplete="off"
            placeholder="请填写特约商户号"
          ></el-input>
        </el-form-item>
        <!-- 商户地域 -->
        <el-form-item label="商户地域" label-width="120px" prop="districtId">
          <el-cascader
            v-model.trim="address"
            :options="cityOpt"
            :props="{ expandTrigger: 'hover' }"
            placeholder="请选择商户地域"
          ></el-cascader>
        </el-form-item>
        <!-- 营业执照 -->
        <el-form-item
          label="营业执照"
          label-width="120px"
          prop="businessLicenseFileId"
        >
          <div class="upload-btn" @click="onUpload()">
            <img
              v-if="params.licenseUrl"
              :src="params.licenseUrl"
              class="upload-img"
            />
            <i v-else class="el-icon-plus uploader-icon"></i>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editShow = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit('form-ref')">保存</el-button>
      </div>
    </el-dialog>
    <UpLoad @fileIds="getFileIds" ref="up" :fileType="1"></UpLoad>
  </div>
</template>

<script>
import {
  getCommercialList,
  getCommercialEdit,
  getCommercialDel,
  getCommercialStop,
} from "@/api/commercial/commercial";
import { getCityList, getFileList } from "@/api/common";
import { ObjToOpt } from "@/utils/utils";
import { rules } from "@/db/rules";
import { merchantStatusObj, merchantStatusColor } from "@/db/objs";
import UpLoad from "@/components/upload";
export default {
  components: { UpLoad },
  data() {
    return {
      rules,
      ObjToOpt,
      merchantStatusObj,
      merchantStatusColor,
      cityOpt: [], //省市区三级列表
      // table列表
      currentPage: 1, // 当前页
      total: 0, // 总条数
      tableData: [],
      tableParams: {
        page: 1,
        size: 10,
        keyWord: "", //查询关键字
        merchantStatus: "",
      },
      // from表单
      editShow: false, //弹框开关
      editTitle: "", //弹框姓名
      address: "", //商户地域
      params: {
        merchantId: "", //商户ID
        merchantName: "", //商户名称
        legalPerson: "", //商户法人
        adminUserName: "", //商户超管姓名
        telPhone: null, //超管手机
        provinceId: "", //省
        cityId: "", //市
        districtId: "", //区
        businessLicenseFileId: "", //营业执照
        licenseUrl: "", //营业执照图片
        merchantNo:""// 商户号
      },
    };
  },
  mounted() {
    this.getCommercialList();
    this.getCityList();
  },
  watch: {
    address(val) {
      if (val.length) {
        this.params.provinceId = val[0];
        this.params.cityId = val[1];
        this.params.districtId = val[2];
      }
    },
  },
  methods: {
    // 【请求】商户列表
    getCommercialList() {
      let data = this.tableParams;
      getCommercialList(data).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total;
      });
    },

    // 【请求】省市区三级联动
    getCityList() {
      let data = {};
      getCityList(data).then((res) => {
        this.cityOpt = res.data;
      });
    },

    // 【请求】新增商户
    getCommercialEdit() {
      let data = this.params;
      getCommercialEdit(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.editShow = false;
          if (data.merchantId) {
            this.getCommercialList();
          } else {
            this.reload();
          }
        }
      });
    },

    // 【请求】获取文件信息
    getFileList(fileIds) {
      let data = {
        fileIds,
      };
      getFileList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.params.licenseUrl = res.data[0].url;
        }
      });
    },

    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getCommercialList();
    },

    // 【监听】表格模糊查询
    kwChange: _.debounce(function () {
      this.reload();
    }, 500),

    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getCommercialList();
    },

    // 【监听】表格条数点击
    onSizeChange(size) {
      this.tableParams.size = size;
      this.reload();
    },

    // 【监听】新增用户
    onAdd() {
      this.editShow = true;
      this.editTitle = "新增商户";
      this.address = [];
      this.params = {
        merchantId: "", //商户ID
        merchantName: "", //商户名称
        legalPerson: "", //商户法人
        adminUserName: "", //商户超管姓名
        telPhone: "", //超管手机
        provinceId: "", //省
        cityId: "", //市
        districtId: "", //区
        businessLicenseFileId: "", //营业执照
        licenseUrl: "", //营业执照图片
        merchantNo:""
      };
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
    },

    // 【监听】编辑按钮
    onEdit(row) {
      this.editShow = true;
      this.editTitle = "编辑商户";
      this.params = JSON.parse(JSON.stringify(row));
      this.address = [
        Number(this.params.provinceId),
        Number(this.params.cityId),
        Number(this.params.districtId),
      ];
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
    },

    // 【监听】表单提交
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.getCommercialEdit();
        } else {
          return false;
        }
      });
    },

    // 【监听删除】
    onDel(row) {
      let data = {
        merchantId: row.merchantId,
      };
      this.$confirm("你确定要删除吗?", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      }).then(() => {
        getCommercialDel(data).then((res) => {
          if (res.isSuccess == "yes") {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getCommercialList();
          }
        });
      });
    },

    // 【监听】文件上传
    onUpload() {
      this.$refs.up.onUpload();
    },

    // 【监听】获取上传文件ID集合
    getFileIds(fileIds) {
      this.params.businessLicenseFileId = fileIds;
      this.getFileList(fileIds);
    },

    // 【监听】启用
    onStart(row) {
      let data = {
        merchantId: row.merchantId,
        merchantStatus: 1,
      };
      this.$confirm("你确定要启用吗?", "启用", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      }).then(() => {
        getCommercialStop(data).then((res) => {
          if (res.isSuccess == "yes") {
            this.getCommercialList();
            this.$message({
              message: res.message,
              type: "success",
            });
          }
        });
      });
    },

    // 【监听】停用
    onStop(row) {
      let data = {
        merchantId: row.merchantId,
        merchantStatus: 0,
      };
      this.$confirm("你确定要停用吗?", "停用", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      }).then(() => {
        getCommercialStop(data).then((res) => {
          if (res.isSuccess == "yes") {
            this.getCommercialList();
            this.$message({
              message: res.message,
              type: "success",
            });
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.commercial-list {
  padding: 0.15rem;
  box-sizing: border-box;
}

.table-search {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.15rem;
}

.search-L {
  display: flex;
}

.table-footer {
  margin-top: 0.1rem;
  display: flex;
  justify-content: flex-end;
}

.upload-btn {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  width: 1.6rem;
  height: 1.6rem;
}

.upload-btn:hover {
  border-color: #409eff;
}

.uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 1.6rem;
  height: 1.6rem;
  line-height: 1.6rem;
  text-align: center;
}

.upload-img {
  width: 1.6rem;
  height: 1.6rem;
  display: block;
}
</style>
